import { DetailedHTMLProps, HTMLAttributes, Children } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import classes from './SimpleCollapsible.module.scss';

export type SimpleCollapsibleProps = { isExpanded?: boolean; disableAnimation?: boolean } & Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  `on${string}` | 'ref'
>;

export default function SimpleCollapsible({
  isExpanded = true,
  children,
  className,
  disableAnimation,
  ...other
}: SimpleCollapsibleProps) {
  const hasContent = Children.count(children) > 0;
  const visible = isExpanded && hasContent;

  if (disableAnimation) {
    return (
      <div
        className={clsx({ [classes.collapsed]: !visible }, className, {
          'opacity-0': !visible,
          'h-0': !visible,
          'opacity-100': visible,
          'h-auto': visible,
        })}
        {...other}
      >
        {children}
      </div>
    );
  }

  return (
    <AnimatePresence>
      <motion.div
        className={clsx({ [classes.collapsed]: !visible }, className)}
        {...other}
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: visible ? 1 : 0, height: visible ? 'auto' : 0 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
