import { ReactNode } from 'react';
import { Checkbox as AntCheckbox, CheckboxProps as AntCheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import clsx from 'clsx';

import classes from './Checkboxes.module.scss';

export type CheckboxProps = AntCheckboxProps & { size?: CheckboxSize; thin?: boolean };
export type CheckboxSize = 'small' | 'big' | 'bigger';

export function Checkbox({ className, checked, size = 'big', thin, ...props }: CheckboxProps) {
  return (
    <AntCheckbox
      className={clsx(
        classes.checkbox,
        {
          [classes.checked]: checked,
          [classes.small]: size === 'small',
          [classes.big]: size === 'big',
          [classes.bigger]: size === 'bigger',
          [classes.bold]: !thin,
        },
        className
      )}
      checked={checked}
      {...props}
    />
  );
}

export interface CheckboxOption<TValue extends string | number> {
  value: TValue;
  label: ReactNode;
}

export interface CheckboxesProps<TValue extends string | number> {
  options: CheckboxOption<TValue>[];
  selectedValues: TValue[];
  onAdd?: (addedValue: TValue) => void;
  onRemove?: (removedValue: TValue) => void;
  onChange?: (newSelectedValues: TValue[]) => void;
  className?: string;
  disabled?: boolean;
  columns?: number;
  minItemWidth?: number;
  size?: CheckboxSize;
}

export function Checkboxes<TValue extends string | number>({
  className,
  onChange,
  onAdd,
  onRemove,
  options,
  selectedValues,
  disabled,
  columns = 1,
  minItemWidth = 212,
  size,
}: CheckboxesProps<TValue>) {
  const handleCheck = (e: CheckboxChangeEvent) => {
    const value = e.target.value as TValue;
    const isAdded = !selectedValues.includes(value);
    if (isAdded && onAdd) {
      onAdd(value);
    }
    if (!isAdded && onRemove) {
      onRemove(value);
    }
    if (onChange) {
      const newValues = isAdded ? selectedValues.filter((v) => v !== value) : [...selectedValues, value];
      onChange(newValues);
    }
  };

  return (
    <div className={clsx(classes.checkboxesGroup, className)}>
      {options.map(({ label, value }, index) => {
        return (
          <Checkbox
            value={value}
            className={classes.checkboxesItem}
            key={`${index}_${value}`}
            checked={selectedValues.includes(value)}
            onChange={handleCheck}
            disabled={!selectedValues.includes(value) && disabled}
            style={{
              flexBasis: `calc(100%/${columns} - 16px)`,
              minWidth: minItemWidth ?? undefined,
            }}
            size={size}
          >
            {label}
          </Checkbox>
        );
      })}
    </div>
  );
}
