import { ReactNode, Children, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { InView, useInView } from 'react-intersection-observer';

import classes from './SimpleSlider.module.scss';

const liMotionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
};
const emptyMotionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
};

export default function SimpleSlider({
  className,
  listClassName,
  children,
  endApproachThreshold = 0,
  onApproachingEnd,
  itemVisibilityThreshold = 0.5, // 50% of item must be visible for it to be considered in view
  emptyMessage = 'No items',
  isEmpty,
}: {
  className?: string;
  listClassName?: string;
  children: ReactNode | ReactNode[];
  endApproachThreshold?: number;
  onApproachingEnd?: () => void;
  itemVisibilityThreshold?: number;
  emptyMessage?: string;
  isEmpty?: boolean;
}) {
  const containerRef = useRef<HTMLUListElement | null>(null);
  const [edgesVisibility, setEdgesVisibility] = useState<Record<number, boolean>>({});

  const [inViewContainerRef, isContainerVisible] = useInView({
    initialInView: false,
    threshold: itemVisibilityThreshold,
  });
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => inViewContainerRef?.(containerRef.current), [containerRef.current]);

  const lastItemIndex = Children.count(children) - 1;
  const thresholdIndex = Math.max(0, lastItemIndex - endApproachThreshold);
  const handleItemInViewChange = ({ isItemInView, itemIndex }: { itemIndex: number; isItemInView: boolean }) => {
    setEdgesVisibility((items) => ({
      ...items,
      [itemIndex]: isItemInView,
    }));
    if (itemIndex >= thresholdIndex && isItemInView) {
      onApproachingEnd?.();
    }
  };

  const handleNext = () => {
    const container = containerRef.current;
    if (!container) {
      console.error('Somehow handleNext is called when ccontainer is not rendered');
      return; // should never happen
    }
    container.scrollBy({
      behavior: 'smooth',
      left: container.getBoundingClientRect().width,
    });
  };
  const handlePrev = () => {
    const container = containerRef.current;
    if (!container) {
      console.error('Somehow handlePrev is called when ccontainer is not rendered');
      return; // should never happen
    }
    container.scrollBy({
      behavior: 'smooth',
      left: -container.getBoundingClientRect().width,
    });
  };

  const hasNext = isContainerVisible && !edgesVisibility[lastItemIndex];
  const hasPrev = isContainerVisible && !edgesVisibility[0];

  return (
    <div className={clsx(classes.root, className)}>
      <ul ref={containerRef} className={clsx(classes.container, listClassName)}>
        <AnimatePresence initial={false}>
          {Children.map(children, (child, itemIndex) => (
            <InView
              initialInView={false}
              threshold={itemVisibilityThreshold}
              onChange={(isItemInView) =>
                handleItemInViewChange({
                  isItemInView,
                  itemIndex,
                })
              }
              skip={![0, thresholdIndex, lastItemIndex].includes(itemIndex)}
            >
              {({ ref }) => (
                <motion.li {...liMotionProps} className={classes.item} ref={ref}>
                  {child}
                </motion.li>
              )}
            </InView>
          ))}
        </AnimatePresence>
      </ul>
      <button
        className={clsx(classes.button, classes.buttonPrev, {
          [classes.buttonShow]: hasPrev,
        })}
        data-testid="slider-prev"
        onClick={handlePrev}
      >
        <FontAwesomeIcon icon={faAngleLeft} className={classes.icon} />
      </button>
      <button
        className={clsx(classes.button, classes.buttonNext, {
          [classes.buttonShow]: hasNext,
        })}
        data-testid="slider-next"
        onClick={handleNext}
      >
        <FontAwesomeIcon icon={faAngleRight} className={classes.icon} />
      </button>
      <AnimatePresence initial={false}>
        {!!isEmpty && (
          <motion.div className={classes.empty} {...emptyMotionProps}>
            <p className={classes.emptyMessage}>{emptyMessage}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
