import { DetailedHTMLProps, HTMLAttributes, useEffect } from 'react';
import clsx from 'clsx';

import classes from './FullScreenContainer.module.scss';

export type FullScreenContainerProps = {
  isFullScreen: boolean;
  toggleFullScreen?: () => void;
  fullScreenClassName?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function FullScreenContainer({
  className,
  isFullScreen,
  toggleFullScreen,
  fullScreenClassName,
  ...other
}: FullScreenContainerProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isFullScreen && event.key === 'Escape') {
        toggleFullScreen?.();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isFullScreen, toggleFullScreen]);

  return (
    <div
      {...other}
      className={clsx({ [classes.fullscreen]: isFullScreen }, className, isFullScreen && fullScreenClassName)}
    />
  );
}
