import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { AddOrEditFavoritePlayerDialogInfoProps } from './types';

const { invokeDialog: addOrEditFavoritePlayer, registerDialog } = createDialogSingleton<
  AddOrEditFavoritePlayerDialogInfoProps,
  void
>();
const addFavoritePlayer = (props: Omit<AddOrEditFavoritePlayerDialogInfoProps, 'favoriteId'>) =>
  addOrEditFavoritePlayer(props);
const editFavoritePlayer = addOrEditFavoritePlayer;

export { addFavoritePlayer, editFavoritePlayer, registerDialog };
export type AddOrEditFavoritePlayerDialogProviderProps = DialogProviderProps<
  AddOrEditFavoritePlayerDialogInfoProps,
  void
>;
