import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { UserPreferencesModalProps } from './types';

const { invokeDialog: showUserPreferencesModal, registerDialog } = createDialogSingleton<
  UserPreferencesModalProps,
  void
>();

export { showUserPreferencesModal, registerDialog };
export type UserPreferencesModalProviderProps = DialogProviderProps<UserPreferencesModalProps, void>;
