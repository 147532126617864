import { UseQueryOptions, useQuery } from 'react-query';
import { getFavoritePlayersCategories } from '../../services/sciApi/favorites';
import { FavoriteCategory } from '../../sci-ui-components/types/favoriteCategory';
import { ApiError } from '../../utils/api';

export const keyPrefix = 'favorite-players-categories';
type Key = [typeof keyPrefix];

export default function useFavoritePlayersCategories(
  options: UseQueryOptions<FavoriteCategory[], ApiError, FavoriteCategory[], Key> = {}
) {
  const queryResult = useQuery<FavoriteCategory[], ApiError, FavoriteCategory[], Key>(
    ['favorite-players-categories'],
    ({ signal }) => getFavoritePlayersCategories(signal),
    {
      ...options,
      enabled: options?.enabled ?? true,
    }
  );

  return queryResult;
}
