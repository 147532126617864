import React, { ReactNode } from 'react';
import classes from './ChartTooltip.module.scss';

export interface ChartTooltipProps {
  title?: ReactNode;
  items?: (ChartTooltipItem | null)[];
}

export interface ChartTooltipItem {
  id: string | number;
  label: ReactNode;
  stats?: ReactNode | ReactNode[];
  color?: string;
  preview?: ReactNode | ReactNode[];
}

export default function ChartTooltip({ title, items }: ChartTooltipProps) {
  if (!items?.length) {
    return null;
  }
  return (
    <div className={classes.root}>
      {!!title && <h5 className={classes.label}>{title}</h5>}
      <ul className={classes.lines}>
        {items.map((item) => {
          if (!item) {
            return null;
          }
          const { id, stats = null, color, label, preview } = item;
          return (
            <li className={classes.line} key={id}>
              <div className={classes.indicator} style={{ backgroundColor: color }} />
              <div className={classes.preview}>{preview}</div>
              <div className={classes.lineContents}>
                <h6 className={classes.lineHeader}>{label}</h6>
                <div className={classes.lineStats} style={{ color }}>
                  {stats}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
