import React, { Children, cloneElement, isValidElement, ReactNode, ReactElement } from 'react';
import clsx from 'clsx';
import { BarChart, LineChart, ComposedChart, ResponsiveContainer, PieChart } from 'recharts';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { FullScreenButton } from '../../FullScreen/index';
import NoticeText from '../../NoticeText/NoticeText';

import classes from './ChartWrapper.module.scss';

export interface ChartWrapperProps {
  className?: string;
  isLoading: boolean;
  isError?: boolean;
  errorBlock?: ReactNode;
  height?: number;
  minHeight?: number;
  aspectRatio?: number;
  chartHeader?: ReactNode;
  chartFooter?: ReactNode;
  chartSettings?: ReactNode | ReactNode[];
  chartLegend?: ReactNode;
  isFullScreen?: boolean;
  toggleFullScreen?: () => void;
  isEmpty?: boolean;
  emptyMessage?: string;
  headerSectionClassName?: string;
}

export default function ChartWrapper({
  className,
  isLoading,
  aspectRatio,
  height,
  minHeight,
  chartHeader,
  chartFooter,
  chartLegend,
  chartSettings,
  errorBlock = null,
  isError,
  isFullScreen,
  toggleFullScreen,
  children,
  isEmpty = false,
  emptyMessage = 'No data found',
  headerSectionClassName,
}: ChartWrapperProps & {
  children: ReactElement<(typeof BarChart | typeof LineChart | typeof ComposedChart | typeof PieChart)['defaultProps']>;
}) {
  const childrenWithPropsOverrides = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { className: classes.chart });
    }
    throw new Error(`${child} is not a valid react element`);
  });

  return (
    <div className={clsx(classes.root, { [classes.noHeight]: !height }, className)}>
      {(!!chartHeader || !!toggleFullScreen || !!chartSettings) && (
        <div className={clsx(classes.headerSection, headerSectionClassName)}>
          {chartHeader}
          <div className={classes.spacer} />
          {(!!chartSettings || !!toggleFullScreen) && (
            <div className={classes.settings}>
              {chartSettings}
              {!!toggleFullScreen && (
                <FullScreenButton isFullScreen={isFullScreen ?? false} toggleFullScreen={toggleFullScreen} />
              )}
            </div>
          )}
        </div>
      )}
      {!isError && (
        <div className={classes.wrapper}>
          <ResponsiveContainer
            width={!!chartLegend ? '65%' : '100%' /* piechart was being horizontally cut off at 60% */}
            height={height}
            minHeight={minHeight}
            aspect={aspectRatio}
            debounce={100}
          >
            {isEmpty ? <></> : childrenWithPropsOverrides[0]}
          </ResponsiveContainer>
          {(!!chartLegend || !!toggleFullScreen) && <div className={classes.legendSection}>{chartLegend}</div>}
        </div>
      )}
      {isLoading && <LoadingSpinner className={classes.loading} />}
      {isEmpty && !isLoading && <NoticeText className={classes.emptyMessage}>{emptyMessage}</NoticeText>}
      {!!isError && errorBlock}
      {!!chartFooter && <div className={classes.footerSection}>{chartFooter}</div>}
    </div>
  );
}
