import { faArrowsMaximize, faMinimize } from '@fortawesome/pro-thin-svg-icons';
import IconButton, { IconButtonProps } from '../buttons/IconButton/IconButton';

export default function FullScreenButton({
  className,
  isFullScreen,
  toggleFullScreen,
  ...other
}: Omit<IconButtonProps, 'faIcon' | 'onClick' | 'title'> & {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}) {
  return (
    <IconButton
      {...other}
      className={className}
      faIcon={faArrowsMaximize}
      faHighlightedIcon={faMinimize}
      highlighted={isFullScreen}
      onClick={toggleFullScreen}
      title={`toggle fullscreen ${isFullScreen ? 'off' : 'on'}`}
    />
  );
}
