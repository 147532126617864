import { DetailedHTMLProps, ForwardedRef, forwardRef, HTMLAttributes, MouseEvent, ReactNode } from 'react';
import clsx from 'clsx';
import classes from './TileContainer.module.scss';

export type TileContainerProps = {
  className?: string;
  children: ReactNode;
  onClick?: (e: MouseEvent) => void;
  href?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export default forwardRef<HTMLAnchorElement | HTMLDivElement, TileContainerProps>(function TileContainer(
  { className, children, href, onClick, ...other }: TileContainerProps,
  ref
) {
  if (href) {
    return (
      <a
        {...other}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={clsx(classes.root, classes.clickable, classes.anchor, className)}
        onClick={onClick}
        href={href}
      >
        {children}
      </a>
    );
  }
  if (onClick) {
    return (
      <div
        {...other}
        ref={ref as ForwardedRef<HTMLDivElement>}
        role="button"
        tabIndex={0}
        className={clsx(classes.root, classes.clickable, className)}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
  return (
    <div {...other} ref={ref as ForwardedRef<HTMLDivElement>} className={clsx(classes.root, className)}>
      {children}
    </div>
  );
});
