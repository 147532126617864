import React, { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import classes from './FormSection.module.scss';

export type FormSectionVariant = 'primary' | 'secondary';

export interface FormSectionProps {
  className?: string;
  contentClass?: string;
  title?: ReactNode;
  children: ReactNode;
  variant?: FormSectionVariant;
  noPadding?: boolean;
}

export default forwardRef<HTMLDivElement, FormSectionProps>(function FormSection(
  { className, contentClass, children, title, variant = 'primary', noPadding = false },
  ref
) {
  return (
    <section
      ref={ref}
      className={clsx(
        {
          [classes.rootSecondary]: variant === 'secondary',
          [classes.noPadding]: noPadding,
        },
        className
      )}
    >
      {title && (
        <h5
          className={clsx(classes.title, {
            [classes.primary]: variant === 'primary',
            [classes.secondary]: variant === 'secondary',
          })}
        >
          {title}
        </h5>
      )}
      <div className={clsx(classes.content, contentClass)}>{children}</div>
    </section>
  );
});
