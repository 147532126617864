import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import classes from './LoadingSpinner.module.scss';

interface Props {
  className?: string;
  block?: boolean;
  size?: 'small' | 'default' | 'large';
}

export default function LoadingSpinner({ className, block = false, size = 'default' }: Props) {
  return (
    <Spin
      className={clsx({ [classes.block]: block }, className)}
      indicator={<LoadingOutlined className={classes.icon} spin />}
      size={size}
    />
  );
}
