import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from 'react-query';
import {
  getFavoritePlayersByCategory,
  GetFavoritePlayersByCategoryParams,
  GetFavoritePlayersByCategoryResponse,
} from '../../services/sciApi/favorites/index';
import { WithNullable } from '../../utils/tsUtils';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { FavoritePlayer } from '../../sci-ui-components/types/favoritePlayer';

export type { GetFavoritePlayersByCategorySortByOption } from '../../services/sciApi/favorites/index';

export const keyPrefix = 'favorite-players';
export type InfiniteQueryParams = Omit<WithNullable<GetFavoritePlayersByCategoryParams, 'categoryId'>, 'offset'>;
type InfiniteKey = [typeof keyPrefix, Omit<GetFavoritePlayersByCategoryParams, 'offset'>];
export type PaginatedQueryParams = WithNullable<GetFavoritePlayersByCategoryParams, 'categoryId'>;
type PaginatedKey = [typeof keyPrefix, GetFavoritePlayersByCategoryParams];

export function useFavoritePlayersByCategoryInfinite(
  { categoryId, ...params }: InfiniteQueryParams,
  options: UseInfiniteQueryOptions<
    GetFavoritePlayersByCategoryResponse,
    ApiError,
    GetFavoritePlayersByCategoryResponse,
    GetFavoritePlayersByCategoryResponse,
    InfiniteKey
  > = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useInfiniteQuery(
    [keyPrefix, { ...params, categoryId: categoryId! }],
    async ({ pageParam: offset = 0, signal }) =>
      getFavoritePlayersByCategory(
        {
          ...params,
          categoryId: categoryId!,
          offset,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...options,
      enabled: !!isLoggedIn && !!categoryId && (options?.enabled ?? true),
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.totalCount || !params.limit) {
          return null;
        }
        const totalItems = allPages.reduce((acc, { items }) => acc + items.length, 0);
        if (lastPage.totalCount <= totalItems) {
          return null;
        }
        return totalItems;
      },
    }
  );

  const data = useMemo(
    () => queryResult.data?.pages?.reduce<FavoritePlayer[]>((acc, { items }) => acc.concat(items), []) ?? [],
    [queryResult.data]
  );

  return {
    ...queryResult,
    data,
  };
}

export function useFavoritePlayersByCategoryPaginated(
  { categoryId, ...params }: PaginatedQueryParams,
  options: UseQueryOptions<
    GetFavoritePlayersByCategoryResponse,
    ApiError,
    GetFavoritePlayersByCategoryResponse,
    PaginatedKey
  > = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery(
    [keyPrefix, { ...params, categoryId: categoryId! }],
    async ({ signal }) => getFavoritePlayersByCategory({ ...params, categoryId: categoryId! }, signal),
    {
      ...options,
      enabled: !!isLoggedIn && !!categoryId && (options?.enabled ?? true),
    }
  );

  return queryResult;
}
