import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCircleInfo, faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import toast from 'sci-ui-components/toast/toast';

const DURATION = 4.5;
const ICON_SIZE = 25;

interface ToastOptions {
  message?: string;
  description?: ReactNode;
  isDisappearing?: boolean;
}

export const showSuccess = ({ message = 'Success', description, isDisappearing = true }: ToastOptions) => {
  toast({
    type: 'success',
    icon: <FontAwesomeIcon icon={faCircleCheck} fontSize={ICON_SIZE} color="var(--success)" />,
    message,
    description,
    duration: isDisappearing ? DURATION : 0,
  });
};

export const showInfo = ({ message, description, isDisappearing = true }: ToastOptions) => {
  toast({
    type: 'info',
    icon: <FontAwesomeIcon icon={faCircleInfo} fontSize={ICON_SIZE} color="var(--info)" />,
    message,
    description,
    duration: isDisappearing ? DURATION : 0,
  });
};

export const showWarning = ({ message = 'Warning', description, isDisappearing = true }: ToastOptions) => {
  toast({
    type: 'warning',
    icon: <FontAwesomeIcon icon={faCircleExclamation} fontSize={ICON_SIZE} color="var(--warning)" />,
    message,
    description,
    duration: isDisappearing ? DURATION : 0,
  });
};

export const showError = ({ message = 'Error', description, isDisappearing = true }: ToastOptions) => {
  toast({
    type: 'error',
    icon: <FontAwesomeIcon icon={faCircleXmark} fontSize={ICON_SIZE} color="var(--error)" />,
    message,
    description,
    duration: isDisappearing ? DURATION : 0,
  });
};
