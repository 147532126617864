import { format, parseISO } from 'date-fns';
import { defaultDateFormat } from '../../utils/date';
import formatStatValue from '../../utils/formatStatValue';

export function formatAsDate<TValue extends any>(value: TValue) {
  const parseValue = typeof value === 'string' ? parseISO(value) : value;
  if (parseValue instanceof Date && !isNaN(parseValue.valueOf())) {
    return format(parseValue, defaultDateFormat);
  }
  return String(value);
}

export function formatAsCount<TValue extends any>(value: TValue) {
  return formatStatValue({
    value: Number(value),
    type: 'count',
  });
}

export function formatAsString(value: number | string) {
  if (typeof value !== 'string') {
    return String(value);
  }
  return value;
}
