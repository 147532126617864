import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { FavoritePlayer } from '@sportscardinvestor/schemas';
import { waitForConfirmation } from '../../sci-ui-components/ConfirmationDialog';
import { moveFavoritePlayerToCategory, MoveFavoritePlayerToCategoryParams } from '../../services/sciApi/favorites';
import { showError, showSuccess } from '../../services/toaster';
import { trackEvent } from '../analytics/trackEvent';
import { keyPrefix as categoriesKeyPrefix } from './useFavoritePlayersCategories';
import { getUseFavoriteIdsQueryKey, useFavoriteIds, UseFavoriteIdsOutput } from './useFavoriteIds';
import { keyPrefix as favoritePlayersKeyPrefix } from './useFavoritePlayersByCategory';
import { getLimitForMembershipTierPermissionCategory } from 'features/user/utils';
import useUser from 'features/user/useUser';
import { mmApiClient, MmApiInput, MmApiOutput, useAuthenticatedMMApiMutation } from '@/services/mmApiX';

export type AddFavoriteMutationInput = Exclude<MmApiInput['private']['favorites']['add'], void>;
export type AddFavoriteMutationOutput = Exclude<MmApiOutput['private']['favorites']['add'], void>;
export type RemoveFavoriteMutationInput = Exclude<MmApiInput['private']['favorites']['remove'], void>;

export default function useFavoritePlayersMutations() {
  const queryClient = useQueryClient();
  const { favoritesCount, getEntityIdByFavoriteId } = useFavoriteIds({ entityType: 'player' });
  const { data: user } = useUser();

  const isUserAtFavoritesLimit = useMemo(() => {
    const limit = getLimitForMembershipTierPermissionCategory(user, 'favorites');
    return limit !== Infinity && favoritesCount >= limit;
  }, [favoritesCount, user]);

  const addFavoritePlayer = useAuthenticatedMMApiMutation(
    async (params: AddFavoriteMutationInput) => {
      if (isUserAtFavoritesLimit) {
        showError({
          description: (
            <div>
              You&apos;ve reached your plan&apos;s limit for favorites.{' '}
              <a
                href="https://www.sportscardinvestor.com/account/?action=subscriptions"
                target="_blank"
                rel="noreferrer"
              >
                Remove a favorite or upgrade your plan to add more favorites.
              </a>
            </div>
          ),
        });
        return;
      }
      const result = await mmApiClient.private.favorites.add.mutate(params);
      return result as FavoritePlayer;
    },
    {
      mutationKey: ['add-favorite-player'],
      onSuccess: (addedFavoritePlayer) => {
        if (isUserAtFavoritesLimit || !addedFavoritePlayer) return;
        const { entityId, entityType, id } = addedFavoritePlayer;
        queryClient.refetchQueries([categoriesKeyPrefix]);
        queryClient.refetchQueries([favoritePlayersKeyPrefix]);
        // update optimistically
        const favoriteIdsQueries = queryClient.getQueriesData<UseFavoriteIdsOutput>(
          getUseFavoriteIdsQueryKey({ entityType })
        );
        favoriteIdsQueries?.forEach(([key, items]) => {
          queryClient.setQueryData<UseFavoriteIdsOutput>(key, [
            ...items,
            {
              entityId,
              id,
            },
          ]);
        });
        queryClient.refetchQueries(getUseFavoriteIdsQueryKey({ entityType }));
        showSuccess({
          message: 'Successfully added to Favorite Players',
        });
        trackEvent({
          eventName: 'FAVORITES_PLAYERS_ADDED',
          playerId: entityId,
        });
      },
      onError: () => {
        showError({
          message: 'Failed to add to Favorite Players. Please try again.',
        });
      },
    }
  );

  const moveToCategory = useMutation({
    mutationFn: (params: MoveFavoritePlayerToCategoryParams) => moveFavoritePlayerToCategory(params),
    mutationKey: ['move-favorite-player-category'],
    onSuccess: () => {
      queryClient.refetchQueries([categoriesKeyPrefix]);
      queryClient.refetchQueries([favoritePlayersKeyPrefix]);
      showSuccess({
        message: 'Successfully changed the Category',
      });
      trackEvent({
        eventName: 'FAVORITES_PLAYERS_MOVED_CATEGORY',
      });
    },
    onError: () => {
      showError({
        message: 'Failed to change Favorite Players Category. Please try again.',
      });
    },
  });

  const removeFavoritePlayer = useAuthenticatedMMApiMutation(
    async (params: RemoveFavoriteMutationInput) => mmApiClient.private.favorites.remove.mutate(params),
    {
      mutationKey: ['remove-favorite-player'],
      onSuccess: (_, { id }) => {
        const entityId = getEntityIdByFavoriteId(id);
        // update optimistically
        const favoriteIdsQueries = queryClient.getQueriesData<UseFavoriteIdsOutput>(
          getUseFavoriteIdsQueryKey({ entityType: 'player' })
        );
        favoriteIdsQueries?.forEach(([key, ids]) => {
          queryClient.setQueryData(
            key,
            ids.filter((item) => item.id !== id)
          );
        });
        // update
        queryClient.refetchQueries(getUseFavoriteIdsQueryKey({ entityType: 'player' }));
        queryClient.refetchQueries([categoriesKeyPrefix]);
        queryClient.refetchQueries([favoritePlayersKeyPrefix]);
        showSuccess({
          message: 'Removed from Favorite Players',
        });
        trackEvent({
          eventName: 'FAVORITES_PLAYERS_REMOVED',
          playerId: entityId,
        });
      },
      onError: () => {
        showError({
          message: 'Failed to remove from Favorites. Please try again.',
        });
      },
    }
  );

  const removeFavoritePlayerWithConfirmation = async ({ id }: RemoveFavoriteMutationInput) => {
    const confirmed = await waitForConfirmation({
      title: 'Delete from Favorite Players',
      description: `Are you sure you want to delete this Player from Favorites?`,
    });
    if (confirmed) {
      await removeFavoritePlayer.mutateAsync({
        id,
      });
    } else {
      trackEvent({
        eventName: 'FAVORITES_PLAYERS_DELETE_CANCELLED',
      });
    }
  };

  return {
    addFavoritePlayer,
    moveToCategory,
    removeFavoritePlayer,
    removeFavoritePlayerWithConfirmation,
  };
}
