import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { isFormattableType } from '../../utils/formatStatValue';
import { StatType } from '../../types/statType';

import classes from './DashboardStat.module.scss';
import useFormatStatValue from 'hooks/useFormatStatValue';

export type { StatType } from '../../types/statType';

export const dashboardStatSizes = ['small', 'medium', 'large'] as const;
export type DashboardStatSize = (typeof dashboardStatSizes)[number];
export const dashboardStatVariants = ['dashboard', 'context'] as const;
export type DashboardStatVariant = (typeof dashboardStatVariants)[number];

export interface DashboardStatProps {
  label?: string;
  value: number | string | null | undefined | ReactNode;
  type?: StatType;
  className?: string;
  highlightChange?: boolean;
  faIcon?: IconDefinition;
  variant?: DashboardStatVariant;
  size?: DashboardStatSize;
  inLine?: boolean;
  appendedLabel?: string | null | ReactNode | undefined;
  additionalStat?: {
    value: number | string | null | undefined | ReactNode;
    type?: StatType;
  };
  textClassName?: string;
}

export default function DashboardStat({
  label,
  value,
  type = 'price',
  size = 'large',
  variant = 'dashboard',
  className,
  faIcon,
  highlightChange,
  inLine,
  appendedLabel,
  additionalStat,
  textClassName,
}: DashboardStatProps) {
  const { formatStatValue } = useFormatStatValue();

  let formattedValue = value;
  if (isFormattableType(value)) {
    formattedValue = formatStatValue({
      value,
      type,
      defaultValue: 'N/A',
      treatZeroAsNull: true,
    });
  }
  const isPositive = typeof value === 'number' && value > 0;
  const isNegative = typeof value === 'number' && value < 0;

  let formattedAdditionalValue = additionalStat?.value;
  if (formattedAdditionalValue && isFormattableType(formattedAdditionalValue)) {
    formattedAdditionalValue = formatStatValue({
      value: formattedAdditionalValue,
      type: additionalStat?.type ?? type,
      defaultValue: 'N/A',
      treatZeroAsNull: true,
    });
  }

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.small]: size === 'small',
          [classes.medium]: size === 'medium',
          [classes.large]: size === 'large',
        },
        className
      )}
    >
      {!!faIcon && (
        <div
          className={clsx(classes.iconBox, {
            [classes.iconBoxDashboard]: variant === 'dashboard',
            [classes.iconBoxContext]: variant === 'context',
          })}
        >
          <FontAwesomeIcon icon={faIcon} className={classes.icon} />
        </div>
      )}
      <div className={classes.column}>
        <p
          className={clsx(textClassName, classes.value, {
            [classes.valuePositive]: highlightChange && isPositive,
            [classes.valueNegative]: highlightChange && isNegative,
          })}
          title={`${label ? `${label}: ` : ''}${formattedValue}`}
        >
          {formattedValue}
          {inLine && !!label && <span className={classes.inLineLabel}>{label}</span>}
        </p>
        {!inLine && !!label && <p className={classes.label}>{label}</p>}
        {!inLine && !!appendedLabel && <p className={classes.label}>{appendedLabel}</p>}
        {formattedAdditionalValue && <p className={classes.label}>({formattedAdditionalValue})</p>}
      </div>
    </div>
  );
}
