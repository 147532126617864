const xsMax = '419.9px';
const smMin = '420px';
const smMax = '719.9px';
const mdMin = '720px';
const mdMax = '1365.9px';
const mlMin = '1366px';
const mlMax = '1566px';
const lgMin = '1366px';
const lgMax = '1919.9px';
const xlMin = '1920px';

export const xs = `(max-width: ${xsMax})`;
export const sm = `(min-width: ${smMin}) and (max-width: ${smMax})`;
export const md = `(min-width: ${mdMin}) and (max-width: ${mdMax})`;
export const ml = `(min-width: ${mlMin}) and (max-width: ${mlMax})`;
export const lg = `(min-width: ${lgMin}) and (max-width: ${lgMax})`;
export const xl = `(min-width: ${xlMin})`;

export const breakpoints = ['xs', 'sm', 'md', 'ml', 'lg', 'xl'] as const;
export type Breakpoint = (typeof breakpoints)[number];
export const queries: Record<Breakpoint, string> = {
  xs,
  sm,
  md,
  ml,
  lg,
  xl,
};
