import { CSSProperties, ReactNode } from 'react';
import { notification } from 'antd';
import clsx from 'clsx';

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './toast.module.scss';

export interface ToasterArgs {
  type: 'success' | 'info' | 'warning' | 'error';
  message?: string;
  description?: ReactNode;
  duration: number;
  icon: ReactNode;
  className?: string;
  closeIcon?: ReactNode;
  style?: CSSProperties | undefined;
}

export default function toast({
  type,
  icon,
  message,
  description,
  duration,
  className,
  closeIcon,
  style,
}: ToasterArgs) {
  return notification[type]({
    message,
    description,
    duration,
    icon,
    className: clsx(classes.root, className),
    closeIcon: closeIcon ?? <FontAwesomeIcon icon={faXmark} fontSize={20} color="var(--gray)" />,
    style,
  });
}
