import { ReactNode, createContext, useContext, useMemo } from 'react';
import { scrollablePageContentElementId } from 'sci-ui-components/styles/constants';

type PopupContainerContextValue = {
  getPopupContainer: () => HTMLElement;
};

function getDefaultPopupContainer() {
  return document.getElementById(scrollablePageContentElementId) ?? document.body;
}

const PopupContainerContext = createContext<PopupContainerContextValue>({
  getPopupContainer: getDefaultPopupContainer,
});

export function PopupContainerProvider({
  popupContainerId,
  children,
}: {
  popupContainerId: string;
  children: ReactNode;
}) {
  return (
    <PopupContainerContext.Provider
      value={useMemo(
        () => ({
          getPopupContainer: () => document.getElementById(popupContainerId) ?? getDefaultPopupContainer(),
        }),
        [popupContainerId]
      )}
    >
      {children}
    </PopupContainerContext.Provider>
  );
}

export function useSelectPopupContainer() {
  return useContext(PopupContainerContext);
}
