import React, { ReactNode } from 'react';
import ChartTooltip, { ChartTooltipItem } from '../ChartTooltip/ChartTooltip';
import { DataPayload, RenderPreviewInTooltipMap, RenderStatsInTooltipMap } from './types';
import useFormatStatValue from 'hooks/useFormatStatValue';

export interface TimeLineChartTooltipProps<TItem extends any> {
  labelFormatter?: (label: any, payload: DataPayload<TItem>[]) => ReactNode;
  label: any;
  renderPreviewInTooltipMap?: RenderPreviewInTooltipMap<TItem>;
  renderLineStatsInTooltipMap?: RenderStatsInTooltipMap<TItem>;
  payload: DataPayload<TItem>[];
}

export default function TimeLineChartTooltip<TItem extends any>({
  label,
  payload,
  labelFormatter,
  renderPreviewInTooltipMap,
  renderLineStatsInTooltipMap,
}: TimeLineChartTooltipProps<TItem>) {
  const { formatStatValue } = useFormatStatValue();
  if (!payload?.length) {
    return null;
  }
  return (
    <ChartTooltip
      title={label && (labelFormatter ? labelFormatter(label, payload) : label)}
      items={payload.map<ChartTooltipItem | null>(({ id, payload, color, name }) => {
        if (id?.includes('-trend') || id?.includes('-noLegend')) {
          return null;
        }
        const renderPreviewInTooltip = renderPreviewInTooltipMap && renderPreviewInTooltipMap[id];
        const renderLineStatsInTooltip = renderLineStatsInTooltipMap && renderLineStatsInTooltipMap[id];
        return {
          id,
          label: name,
          color,
          preview: renderPreviewInTooltip?.(payload),
          stats: renderLineStatsInTooltip?.(payload, { color }, formatStatValue),
        };
      })}
    />
  );
}
