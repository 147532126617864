import { useEffect, useState } from 'react';

export default function useMediaQuery(query: string | null, shouldSetInitialValue = false) {
  const [isMatch, setIsMatch] = useState<boolean | null>(
    shouldSetInitialValue && query ? window.matchMedia(query).matches : null
  );

  useEffect(() => {
    if (!query) {
      return;
    }
    const mediaQueryList = window.matchMedia(query);
    setIsMatch(mediaQueryList.matches);
    const handler = (e: MediaQueryListEvent) => setIsMatch(e.matches);
    mediaQueryList.addEventListener('change', handler);
    return () => mediaQueryList.removeEventListener('change', handler);
  }, [query]);

  return isMatch;
}
