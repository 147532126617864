import { queries, Breakpoint, breakpoints } from '../styles/breakpoints';
import useMediaQuery from './useMediaQuery';

export function useBreakpoints(shouldSetInitialValue = false): Record<Breakpoint, boolean> {
  return {
    xs: useMediaQuery(queries.xs, shouldSetInitialValue) ?? false,
    sm: useMediaQuery(queries.sm, shouldSetInitialValue) ?? false,
    md: useMediaQuery(queries.md, shouldSetInitialValue) ?? false,
    ml: useMediaQuery(queries.ml, shouldSetInitialValue) ?? false,
    lg: useMediaQuery(queries.lg, shouldSetInitialValue) ?? false,
    xl: useMediaQuery(queries.xl, shouldSetInitialValue) ?? false,
  };
}

export function useBreakpoint(shouldSetInitialValue = false, defaultValue?: Breakpoint): Breakpoint | null {
  const matches = useBreakpoints(shouldSetInitialValue);
  return breakpoints.find((breakpoint) => matches[breakpoint]) ?? defaultValue ?? null;
}
