import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { AddOrEditFavoritePlayersCategoryDialogResult, AddOrEditFavoritePlayersCategoryDialogInfoProps } from './types';

const { invokeDialog: addOrEditFavoritePlayersCategory, registerDialog } = createDialogSingleton<
  AddOrEditFavoritePlayersCategoryDialogInfoProps,
  AddOrEditFavoritePlayersCategoryDialogResult
>();

export { addOrEditFavoritePlayersCategory, registerDialog };
export type AddOrEditFavoritePlayersCategoryDialogProviderProps = DialogProviderProps<
  AddOrEditFavoritePlayersCategoryDialogInfoProps,
  AddOrEditFavoritePlayersCategoryDialogResult
>;
