import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { AddOrEditFavoriteCategoryDialogInfoProps, AddOrEditFavoriteCategoryDialogResult } from './types';

const { invokeDialog: addOrEditFavoriteCategory, registerDialog } = createDialogSingleton<
  AddOrEditFavoriteCategoryDialogInfoProps,
  AddOrEditFavoriteCategoryDialogResult
>();

export { addOrEditFavoriteCategory, registerDialog };
export type AddOrEditFavoriteCategoryDialogProviderProps = DialogProviderProps<
  AddOrEditFavoriteCategoryDialogInfoProps,
  AddOrEditFavoriteCategoryDialogResult
>;
